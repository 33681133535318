import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, BookOpen, Tag, Clock, ChevronRight } from 'lucide-react';
import './style.css';

const blogPosts = [
  {
    id: 1,
    title: "Rising Demand for Data Science Professionals in India",
    description: "Explore the surging demand for data science professionals in India and discover career opportunities in this dynamic field.",
    slug: "rising-demand-for-data-science-professionals-in-india",
    category: "Data Science",
    readTime: "8 min read",
    date: "March 15, 2024"
  },
  {
    id: 2,
    title: "Best Computer Science Courses After 12th",
    description: "Discover the top computer science courses available after 12th standard and their career prospects in 2025.",
    slug: "best-computer-science-courses-after-12th",
    category: "Education",
    readTime: "10 min read",
    date: "March 14, 2024"
  },
  {
    id: 3,
    title: "How to Successfully Switch Jobs in the IT Sector",
    description: "A comprehensive guide to successfully transitioning between IT jobs and advancing your career.",
    slug: "how-to-successfully-switch-jobs-in-the-IT-sector",
    category: "Career",
    readTime: "12 min read",
    date: "March 13, 2024"
  }
];

function BlogsPage() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://api.be-practical.com/api/allblogs');
        const combinedBlogs = [...response.data, ...blogPosts];
        setBlogs(combinedBlogs);
        setFilteredBlogs(combinedBlogs);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch blogs. Please try again later.');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    const filtered = blogs.filter(blog => 
      blog.BlogTitle.toLowerCase().includes(term.toLowerCase()) ||
      blog.blogsCategory.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredBlogs(filtered);
  };

  const filterByCategory = (category) => {
    const filtered = blogs.filter(blog => blog.blogsCategory === category);
    setFilteredBlogs(filtered);
  };

  if (loading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-vh-100 bg-light">
      {/* <header className="bg-white shadow-sm py-4 mb-4">
        <div className="container">
          <h1 className="d-flex align-items-center gap-2 fs-2 mb-0">
            <BookOpen className="text-warning" />
            Tech Blog
          </h1>
        </div>
      </header> */}

      <div className="container">
        <div className="row g-4">
          {/* <div className="col-md-3">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="position-relative mb-4">
                  <input
                    type="text"
                    className="form-control form-control-lg ps-5"
                    placeholder="Search blogs..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <Search className="position-absolute top-50 translate-middle-y ms-2 text-muted" size={20} />
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-md-12">
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {filteredBlogs.map((blog) => (
                <div key={blog._id || blog.id} className="col">
                  <div className="card shadow-sm h-100">
                    <div className="card-header" style={{height:'100px'}} >
                      
                    </div>
                    <div className="card-body d-flex flex-column">
                      <div className="mb-2">

                        <span className="badge bg-warning text-dark me-2">{blog.blogsCategory || blog.category}</span>
                        <small className="text-muted d-flex align-items-center gap-1">
                          {/* <Clock size={14} /> */}
                          {blog.readTime || '5 min read'}
                        </small>
                      </div>
                      <h5 className="card-title">{blog.BlogTitle || blog.title}</h5>
                      <div 
                        className="card-text mb-3"
                        dangerouslySetInnerHTML={{ 
                          __html: blog.BlogDes 
                            ? blog.BlogDes.length > 150 
                              ? blog.BlogDes.substring(0, 150) + '...' 
                              : blog.BlogDes
                            : blog.description || ''
                        }}
                      />
                      <a 
                        href={`/blogs/${blog.slug}`}
                        className="btn btn-dark"
                       
                      >
                        Read More
                        {/* <ChevronRight size={16} /> */}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsPage;